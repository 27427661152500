import React from "react"
import { Link } from "gatsby"
import DispayLeafletMap from "../../components/displaymap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Carousel from "nuka-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import LeafletMap from "../../components/leafletmap"
import { useSelector } from "react-redux"

import groceriesIcon from "../../images/icon/paper-bag.svg"

export const AFSAMEPage = props => {
  const data = useStaticQuery(graphql`
    query imagesMenthe {
      menthe_1: file(relativePath: { eq: "producteur/menthe_1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      menthe_2: file(relativePath: { eq: "producteur/menthe_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      menthe_3: file(relativePath: { eq: "producteur/menthe_3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const panier = useSelector(state => state.panierR)

  const latitude = 46.843044
  const longitude = 6.284788

  const state = {
    markers: [{ key: "marker1", position: [latitude, longitude] }],
    latlng: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 9,
  }

  return (
    <Layout>
      <SEO title="Ferme Bio de menthe et melisse" />
      <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-1">Le cabas de La Planee</h1>
          </div>
        </div>
      </section>
      <section className="pt-5 mt-5">
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-one-third">
              <h2 className="almond is-size-3 has-text-centered">
                La Planée (25160)
              </h2>
              {typeof window !== "undefined" && (
                <DispayLeafletMap
                  style={{ height: "600px" }}
                  markers={state.markers}
                  center={state.latlng}
                  zoom={state.zoom}
                />
              )}
            </div>
            <div className="column">
              <div>
                <Carousel
                  autoplay={true}
                  autoplayInterval={2500}
                  transitionMode={"fade"}
                  wrapAround={true}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronLeft}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronRight}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                >
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.menthe_3.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.menthe_2.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.menthe_1.childImageSharp.fluid}
                  />
                </Carousel>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column ">
              <div>
                <section className="m-2 has-text-justified is-size-4">
                  <div className="box" style={{ background: "#ffff76" }}>
                    <p className="is-4">
                      Le Cabas de La Planée est une association qui met à disposition des casiers dans un point de dépôt à La Planée, et organise la gestion des commandes.
                    </p>
                    <p className="is-4">
                      Les producteurs locaux partenaires, engagés dans une démarche de circuit court, déposent une fois par semaine les produits commandés sur AgroBioConso directement dans les casiers répartis par client.
                    </p>
                  </div>
                  <h2 className="is-size-3 almond">Les producteurs partenaires :</h2>
                  <div className="has-text-centered">
                    <a href="https://producteurs.opendistrib.net/aumoulindelapoirotte" className="tag mr-2 mt-2 is-info is-large">Au moulin de Poirotte (Trépot)</a>
                    <a href="https://fr-fr.facebook.com/pages/category/Livestock-Farm/Au-panier-de-la-Ferme-Aymonin-1846900608905857/" className="tag mr-2 mt-2 is-info is-large">Au panier de la ferme Aymonin (Aubonne)</a>
                    <a href="https://www.fromagerie-bio.net/" className="tag mr-2 mt-2 is-info is-large">Fruitière bio de Chapelle des Bois</a>
                    <a href="https://www.facebook.com/gaecduhautpaturage/" className="tag mr-2 mt-2 is-info is-large">GAEC du Haut Pâturage (Pontarlier)</a>
                    <a href="https://claj-batailleuse.fr/" className="tag mr-2 mt-2 is-info is-large">CLAJ la Batailleuse (Rochejean)</a>
                    <a href="https://www.brasseriebonnebouille.com/" className="tag mr-2 mt-2 is-info is-large">La Bonne Bouille (Pontarlier)</a>
                    <a href="http://semilla25.free.fr/" className="tag mr-2 mt-2 is-info is-large">La Semilla (Les Fourgs)</a>
                    <a href="https://www.paysansdenature.fr/reseau-paysans-de-nature/montrinsant-et-le-chaudron-du-jura-violaine-bruwier-alois-vionnet-gerard-vionnet/" className="tag mr-2 mt-2 is-info is-large">Le Chaudron du Jura (Vaux-et-Chantegrue)</a>
                    <a href="https://leclosdesgrives.com/" className="tag mr-2 mt-2 is-info is-large">Le Clos des Grives (Chille)</a>
                    <a href="https://www.tousvoisins.fr/oye-et-pallet/663567-au-fournil-du-lac" className="tag mr-2 mt-2 is-info is-large">Le fournil du lac (Oye-et-Pallet)</a>
                    <a href="https://www.bienvenue-a-la-ferme.com/bourgognefranchecomte/doubs/arc-sous-cicon/ferme/le-verger-d-arc-sous-cicon/400641" className="tag mr-2 mt-2 is-info is-large">Le verger d'Arc-sous-Cicon</a>
                    <a href="https://www.lesjujupasta.fr/" className="tag mr-2 mt-2 is-info is-large">Les Juju Pasta (Torpes)</a>
                    <a href="http://www.herberiejurassienne.com/adherents/details/42/les-p-tites-plantes" className="tag mr-2 mt-2 is-info is-large">Les petites plantes (Ville du Pont)</a>
                    <a href="https://fr-fr.facebook.com/Ruchersdelarlier/" className="tag mr-2 mt-2 is-info is-large">Les Ruches de l'Arlier (Houtaud)</a>
                    <a href="https://www.mentheetmelisse.fr/" className="tag mr-2 mt-2 is-info is-large">Menthe et Mélisse (La Planée)</a>
                    <a href="https://www.facebook.com/pages/category/Producer/M%C3%A9m%C3%A9-dans-le-bouillon-103772097822728/" className="tag mr-2 mt-2 is-info is-large">Mémé dans le bouillon (Labergement-Sainte-Marie)</a>
                  </div>
                </section>
              </div>
            </div>
            <div className="column is-half has-text-centered">
              {(panier.zone === null && panier.pickupPoint === null) || panier.zone !== 21 ? (
                <Link to="#selectPoint">
                  {" "}
                  <button className="button is-warning mt-4">
                    Sélectionnez un point de retrait pour voir les produits
                  </button>
                </Link>
              ) : (
                <Link to={`/producteur/21-ptit-bio/produits`}>
                  <button className="button is-success is-light is-large mt-4 dreamland is-size-3">
                    <img
                      src={groceriesIcon}
                      style={{
                        width: 40,
                        height: 40,
                        position: "relative",
                        top: 12,
                        marginRight: 15,
                      }}
                      alt="Produits"
                    />
                    Voir les produits
                  </button>
                </Link>
              )}
              <span
                className="ml-4 mt-5 almond is-size-3"
                style={{ display: "block" }}
              >
                Cette association met à disposition le point de dépôt suivant :
              </span>
              {typeof window !== "undefined" && (
                <section id="selectPoint" className="mt-4 producer">
                  <LeafletMap producteur={[21]} />
                </section>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AFSAMEPage
